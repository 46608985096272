/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { Calendar, Col, Modal, Row, Spin, Tooltip } from 'antd';
import Button from 'components/generalUI/Button';
import useListOpenDays from 'apollo/resolvers/openDays/list';
import { LoadingOutlined } from '@ant-design/icons';
import formatDateLocaleES from 'utils/dates/formatDateLocaleES';
import isDayInArray from 'utils/dates/isDayInArray';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import getLastOpenDay from 'utils/dates/getLastOpenDay';
import formatDateStringToLocaleString from 'utils/dates/formatDateToLocaleString';
import captureEvent from 'utils/post-hog';
import * as events from 'utils/post-hog/events';
import * as texts from 'assets/texts/schedulesAndPrices';
import styles from './OpenDayPicker.module.scss';
import { OpenDayPickerProps, OpenDayPickerHeaderProps } from './types';
import OpenDayPickerHeader from './OpenDayPickerHeader';

const OpenDayPickerHeaderSub = ({
  value,
  onChange,
  lastOpenDayDate,
  setSelectedMonth,
  selectedMonth,
}: OpenDayPickerHeaderProps) => {
  return (
    <OpenDayPickerHeader
      value={value}
      onChange={onChange}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      lastOpenDayDate={lastOpenDayDate}
      setSelectedMonth={setSelectedMonth}
      selectedMonth={selectedMonth}
    />
  );
};

const OpenDayPicker: FC<OpenDayPickerProps> = ({
  startDate,
  endDate,
  redirectUrlType,
}) => {
  const { data } = useListOpenDays(startDate, endDate);

  const [possibleDate, setPossibleDate] = useState<string>();
  const [openDays, setOpenDays] = useState(data?.listOpenDays);
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [url, setUrl] = useState<string>();

  const currentMomentDate = moment().startOf('day');
  const lastOpenDay = getLastOpenDay(openDays);
  const lastOpenDayDate = lastOpenDay && new Date(lastOpenDay.day);

  const onPanelChange = (date: moment.Moment) => {
    if (openDays && !isDayInArray(date, openDays)) {
      const localeDateString = formatDateStringToLocaleString(date.toISOString());
      setPossibleDate(localeDateString);
      setUrl(`/${redirectUrlType}?date=${localeDateString}`);
    } else if (openDays && isDayInArray(date, openDays)) {
      setPossibleDate(undefined);
    }
  };

  useEffect(() => {
    formatDateLocaleES();
    if (data) {
      setOpenDays(data?.listOpenDays);
    }
  }, [data]);

  const dateFullCellRender = (value: Moment) => {
    const monthDay = value.date();

    const isInTheFutureOrCurrent = value.isSameOrAfter(currentMomentDate);
    const isBeforeLastOpenDay = lastOpenDayDate && value.toDate() < lastOpenDayDate;
    const isNotOpenDay = openDays && isDayInArray(value, openDays);

    if (selectedMonth === undefined || value.month() !== selectedMonth) {
      return null;
    }

    if (isNotOpenDay) {
      return (
        <div className="ant-picker-cell-disabled">
          {isInTheFutureOrCurrent ? (
            <Tooltip title={isBeforeLastOpenDay ? 'Parque cerrado' : 'Aún no habilitado'}>
              <div
                className={`ant-picker-cell-inner-disabled ant-picker-calendar-date ${
                  isBeforeLastOpenDay ? 'closed-day' : ''
                }`}
              >
                <div className="ant-picker-calendar-date-value">{monthDay}</div>
              </div>
            </Tooltip>
          ) : (
            <div className="ant-picker-cell-inner-disabled ant-picker-calendar-date">
              <div className="ant-picker-calendar-date-value">{monthDay}</div>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="ant-picker-cell-inner ant-picker-calendar-date">
        <div className="ant-picker-calendar-date-value">{monthDay}</div>
      </div>
    );
  };

  return (
    <>
      <Modal
        footer={null}
        className={styles.ImportantInformationModal}
        visible={isModalOpen}
        title={texts.importantInformation}
        onCancel={() => setIsModalOpen(false)}
      >
        <Row className={styles.TextContainer}>
          <Col span={24}>
            <p dangerouslySetInnerHTML={{ __html: texts.onlyOnlineSales }} />
            <p dangerouslySetInnerHTML={{ __html: texts.childrenFree }} />
          </Col>
        </Row>
      </Modal>
      {openDays ? (
        <div className={styles.OpenDayContainer}>
          <Calendar
            className={styles.OpenDayPicker}
            mode="month"
            fullscreen={false}
            validRange={[currentMomentDate, moment(lastOpenDayDate).add(1, 'day')]}
            onSelect={onPanelChange}
            headerRender={({ value, onChange }) =>
              OpenDayPickerHeaderSub({
                value,
                onChange,
                lastOpenDayDate,
                setSelectedMonth,
                selectedMonth,
              })
            }
            dateFullCellRender={dateFullCellRender}
          />

          <Button
            className={styles.OpenDayPickerButton}
            size="large"
            disabled={!possibleDate}
            href={url}
            onClick={() => captureEvent(events.toSelectEntrancesButtonClicked)}
          >
            Seleccionar
          </Button>
          <Button type="link" onClick={() => setIsModalOpen(true)}>
            Información importante
          </Button>
        </div>
      ) : (
        <Spin indicator={<LoadingOutlined className={styles.Loader} spin />} />
      )}
    </>
  );
};

export default OpenDayPicker;
