import { Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/home';
import Button from 'components/generalUI/Button';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import { ParkAvailability } from 'apollo/types';
import formatDateToMonthString from 'utils/dates/formatDateToMonthString';
import moment from 'moment';
import updateDateLocaleES from 'utils/dates/updateDateLocaleES';
import styles from './BuyTickets.module.scss';

const BuyTickets: FC = () => {
  updateDateLocaleES('es');

  const { data: parkAvailabilityData } = useListParkAvailabilityQuery();

  const formatParkAvailability = (availability: ParkAvailability) => {
    const time = `${availability?.aperture} a ${availability?.closure}`;
    const months = `${formatDateToMonthString(
      availability?.startDate
    )} a ${formatDateToMonthString(availability?.endDate)}`;
    const date = new Date().toISOString();
    const dateToCompare = date ? moment(date) : moment();
    const isActualParkAvailability = dateToCompare.isBetween(
      availability.startDate,
      availability.endDate
    );
    return { time, months, isActualParkAvailability };
  };

  return (
    <Row className={styles.Container} align="middle">
      <Col span={24}>
        <Row
          justify="center"
          align="middle"
          gutter={[
            { xs: 24, md: 16 },
            { xs: 16, md: 0 },
          ]}
        >
          <Col>
            <h4 className={styles.TicketSaleTitle}>{texts.ticketSaleTitle}</h4>
          </Col>
          <Col>
            {parkAvailabilityData?.listParkAvailability?.map((availability) => {
              const { time, months, isActualParkAvailability } =
                formatParkAvailability(availability);

              if (!isActualParkAvailability) {
                return null;
              }

              return (
                <div className={styles.ActualParkAvailability} key={availability.id}>
                  <p>
                    {months} - {time}
                  </p>
                </div>
              );
            })}
          </Col>
          <Col>
            <Button className={styles.TicketSaleButton} href="/date" size="large">
              {texts.buyTickets}
            </Button>
          </Col>
          <Col className={styles.SchedulesAndPricesContainer}>
            <Button
              className={styles.SchedulesAndPricesButton}
              href="/prices"
              kind="secondary"
              size="large"
            >
              {texts.prices}
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <p className={styles.TicketDisclaimer}>{texts.ticketsSaleDisclaimer}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BuyTickets;
