import React, { FC, useState } from 'react';
import { Input, Form, message, Select, Row, Col } from 'antd';
import Button from 'components/generalUI/Button';
import * as texts from 'assets/texts/footer';
import useSendEmailMutation from 'apollo/resolvers/contactUs/sendEmail';
import emailRegExp from 'utils/validations/emailRegExp';
import { DefaultOptionType } from 'antd/lib/select';
import styles from './HelpForm.module.scss';

const HelpForm: FC = () => {
  const themes: DefaultOptionType[] = [
    {
      label: 'Visita educativa-pedagógica',
      value: 'Visita educativa-pedagógica',
    },
    {
      label: 'Operadores turísticos',
      value: 'Operadores turísticos',
    },
    {
      label: 'Sugerencias, quejas y reclamos',
      value: 'Sugerencias, quejas y reclamos',
    },
    {
      label: 'Eventos institucionales',
      value: 'Eventos institucionales',
    },
    {
      label: 'Otros',
      value: 'Otros',
    },
  ];

  const [form] = Form.useForm();
  const [useSendEmail] = useSendEmailMutation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedTheme, setSelectedTheme] = useState<string>(themes[0].value as string);

  const onSubmit = async () => {
    setDisabled(true);
    await form.validateFields();
    const values = form.getFieldsValue() as {
      email: string;
      name: string;
      message: string;
    };
    const response = await useSendEmail({
      variables: {
        input: {
          email: values.email,
          name: values.name,
          message: values.message,
          theme: selectedTheme,
        },
      },
    });
    if (response) {
      await message.success(texts.messageSent);
    } else {
      await message.error(texts.messageSentError);
    }
    form.resetFields();
    setDisabled(false);
  };

  return (
    <div className={styles.Form}>
      <Form form={form} layout="vertical">
        <h1 className={styles.FormTitle}>{texts.helpFormTitle}</h1>
        <Row justify="space-between">
          <Col xs={24} md={11}>
            <p className={styles.formLabel}>{texts.helpFormNameField}</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: texts.requiredName }]}
              className={styles.FormItem}
            >
              <Input className={styles.Input} />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <p className={styles.formLabel}>{texts.helpFormEmailField}</p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: texts.requiredEmail },
                {
                  message: texts.invalidEmail,
                  pattern: emailRegExp(),
                },
              ]}
              className={styles.FormItem}
            >
              <Input className={styles.Input} />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.formLabel}>{texts.themeFormMessageField}</p>
        <Form.Item
          name="theme"
          rules={[{ required: true, message: texts.requiredMessage }]}
          className={styles.FormItem}
        >
          <Select
            options={themes}
            placeholder="Selecciona un motivo de contacto"
            onChange={setSelectedTheme}
          />
        </Form.Item>
        <p className={styles.formLabel}>{texts.helpFormMessageField}</p>
        <Form.Item
          name="message"
          rules={[{ required: true, message: texts.requiredMessage }]}
          className={styles.FormItem}
        >
          <Input.TextArea className={styles.Input} />
        </Form.Item>
        <Form.Item className={styles.FormItem}>
          <Button
            className={styles.FormButton}
            shape="round"
            onClick={onSubmit}
            disabled={disabled}
          >
            {texts.helpFormButton}
          </Button>
        </Form.Item>
        <Row justify="end">
          <p className={styles.FormAttentionSchedule}>
            {texts.helpFormAttentionSchedule}
          </p>
        </Row>
      </Form>
    </div>
  );
};

export default HelpForm;
